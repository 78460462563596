.modal-content {
    border: none;
    border-radius: none;
    @include themify($themes) {
        background-color: themed('backgroundColor');
    }
}

.modal-dialog {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    width: $modal-md;
}

.modal-sm {
    width: $modal-sm;
}

.modal-lg { 
    width: $modal-lg;
}

.modal-header {
    @include themify($themes) {
        background-color: themed('foregroundColor');
        border-bottom: 1px solid themed('separator');
        color: themed('textColor');
    }
}

.modal-body {
    @include themify($themes) {
        background-color: themed('foregroundColor');
        color: themed('textColor');
    }

    h3, .section-header > * {
        font-weight: normal;
        text-transform: uppercase;
        @include themify($themes) {
            color: themed('textMuted');
        }
    }
}
.modal .list-group-flush {
    :first-child {
        border-top: none;
    }
    
    :last-child {
        border-bottom: none;
    }
}

.modal .list-group-flush-2fa {
    div {
        border-left: none;
        border-right: none;
    }
    div:first-child {
        border-top: none;
    }
    
    div:last-child {
        border-bottom: none;
    }
}

.modal-footer {
    border-radius: 0.3rem 0.3rem 0 0;
    justify-content: flex-start;
    @include themify($themes) {
        background-color: themed('footerBackgroundColor');
        border-top: 1px solid themed('separator');
    }
}

.close {
    @include themify($themes) {
        color: themed('textColor');
    }
}

#totpImage {
    @include themify($themes) {
        filter: themed('imgFilter');
    }
}

.totp {
    .totp-code {
        @extend .text-monospace;
        font-size: 1.2rem;
    }

    .totp-countdown {
        display: block;
        margin: 3px 3px 0 0;
        user-select: none;

        .totp-sec {
            font-size: 0.85em;
            line-height: 32px;
            position: absolute;
            text-align: center;
            width: 32px;
        }

        svg {
            height: 32px;
            transform: rotate(-90deg);
            width: 32px;
        }

        .totp-circle {
            fill: none;
            @include themify($themes) {
                stroke: themed('primary');
            }

            &.inner {
                stroke-dasharray: 78.6;
                stroke-dashoffset: 0;
                stroke-width: 3;
            }

            &.outer {
                stroke-dasharray: 88;
                stroke-dashoffset: 0;
                stroke-width: 2;
            }
        }
    }

    > .align-items-center {
        margin-bottom: -5px;
    }

    &.low {
        .totp-sec, .totp-code {
            @include themify($themes) {
                color: themed('danger');
            }
        }

        .totp-circle {
            @include themify($themes) {
                stroke: themed('danger');
            }
        }
    }
}

.cdk-drag-preview {
    border-radius: $border-radius;
    opacity: 0.8;
    z-index: $zindex-tooltip !important;
    @include themify($themes) {
        background: themed('cdkDraggingBackground');
    }
}
