.toast-container {
    &.toast-top-right {
        top: 76px;
    }

    .toast-close-button {
        font-size: 18px;
        margin-right: 4px;
    }

    .toast {
        align-items: center;
        background-image: none !important;
        border-radius: $border-radius;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
        display: flex;
        opacity: 1 !important;

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
        }

        &:before {
            color: #FFFFFF;
            float: left;
            font-family: FontAwesome;
            font-size: 25px;
            line-height: 20px;
            margin: auto 0 auto 15px;
        }

        .toast-content {
            padding: 15px;
        }

        .toaster-icon {
            display: none;
        }

        .toast-message {
            p {
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.toast-danger, &.toast-error {
            background-image: none !important;
            @include themify($themes) {
                background-color: themed('danger');
            }

            &, &:before, & .toast-close-button {
                @include themify($themes) {
                    color: themed('dangerTextColor') !important;
                }
            }

            &:before {
                content: "\f0e7";
            }
        }

        &.toast-warning {
            background-image: none !important;
            @include themify($themes) {
                background-color: themed('warning');
            }

            &, &:before, & .toast-close-button {
                @include themify($themes) {
                    color: themed('warningTextColor') !important;
                }
            }

            &:before {
                content: "\f071";
            }
        }

        &.toast-info {
            background-image: none !important;
            @include themify($themes) {
                background-color: themed('info');
            }

            &, &:before, & .toast-close-button {
                @include themify($themes) {
                    color: themed('infoTextColor') !important;
                }
            }

            &:before {
                content: "\f05a";
            }
        }

        &.toast-success {
            background-image: none !important;
            @include themify($themes) {
                background-color: themed('success');
            }

            &, &:before, & .toast-close-button {
                @include themify($themes) {
                    color: themed('successTextColor') !important;
                }
            }

            &:before {
                content: "\f00C";
            }
        }
    }
    .layout_frontend .toast-top-right {
        top: 20px;
    }
}

.layout_frontend .toast-top-right {
    top: 20px;
}
